import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link as LinkGatsby } from "gatsby"
import Layout from "../components/Layout"
import PropTypes from "prop-types"
import { IconChevronLeft } from "../components/icons/Icons"
import Seo from "../components/layout/Seo"
import { uniformUrl } from "../helpers/StringHelpers"
import AdditionalFoms from "../components/common/AdditionalForms"
import BrandsWeBuy from "../components/common/BrandsWeBuy"
import HaveQuestions from "../components/common/HaveQuestions"

const Link = ({ to, className, children }) => {
  return (
    <LinkGatsby className={className + " hover:text-accentMd"} to={to}>
      {children}
    </LinkGatsby>
  )
}
Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const ListGroup = ({ title, content, thirdLevel }) => {
  return (
    <div>
      <div className={"flex justify-between items-center break-after-avoid"}>
        <h2 className="sm:text-xl text-accent font-bold underline decoration-1 underline-offset-4 decoration-accentMd uppercase">
          {title}
        </h2>
      </div>
      <div>
        {content.length > 0 &&
          content.map(secLevelPage => {
            const relatedThirdLevel = thirdLevel.filter(
              category => category.node.strapiParent.name === secLevelPage.node.name
            )
            return (
              <Link key={secLevelPage.node.name} to={secLevelPage.node.url}>
                <h3 className="text-base font-bold">{secLevelPage.node.name}</h3>
                <div className="flex flex-col">
                  {relatedThirdLevel.length > 0 &&
                    relatedThirdLevel.map(category => {
                      return (
                        <h4 className="pl-4 text-base" key={category.node.name}>
                          {category.node.name}
                        </h4>
                      )
                    })}
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}
ListGroup.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
  thirdLevel: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
}

const SiteIndex = ({ pageContext, data }) => {
  // console.log(pageContext, 'page-context');
  // console.log(data, 'data');
  const { category } = data

  const BlogPages = pageContext.listPages?.filter(page => page.node.blog === true) || []
  const ReleasePages = pageContext.listPages?.filter(page => page.node.press_release === true) || []
  const OtherPages =
    pageContext.listPages?.filter(
      page => page.node.press_release !== true && page.node.blog !== true && page.node.slug
    ) || []
  const ProductCategories = pageContext.listPages?.filter(page => page.node.url) || []
  const ProductsList = data?.products?.edges
  const priority = [
    "Fine Luxury Watches",
    "Luxury Jewelry",
    "Watch Bands & Straps",
    "New Arrivals",
  ].reverse()
  const firstLevel = ProductCategories.filter(product => product.node.level === 1)
    .map(item => {
      return { ...item, order: -priority.indexOf(item.node.name) }
    })
    // sort by order than by name
    .sort((a, b) => {
      if (a.order === b.order) {
        return a.node.name > b.node.name ? 1 : -1
      }
      return a.order > b.order ? 1 : -1
    })
  //.sort((a, b) => a.order - b.order);
  const secondLevel = ProductCategories.filter(product => product.node.level === 2)
  const thirdLevel = ProductCategories.filter(product => product.node.level === 3)

  const modifyCategoryMetaTitle = metaTitle => {
    // replace "| ..." with empty string
    // add " Sitemap | " at the beginning
    // make sure to have less than 60 characters total
    let title = "Sitemap | " + metaTitle.replace(/| [^|]*$/, "")
    if (title.length > 60) {
      title = title.substr(0, 57) + "..."
    }
    return title
  }
  const modifyCategoryMetaDescription = metaDescription => {
    // replace "| ..." with empty string
    // add " Sitemap | " at the beginning
    // make sure to have less than 155 characters total
    let desc = "Sitemap | " + metaDescription.replace(/| [^|]*$/, "")
    if (desc.length > 155) {
      desc = desc.substr(0, 152) + "..."
    }
    return desc
  }
  // console.log(thirdLevel);
  return (
    <Layout className={"mx-auto mt-20 md:mt-5 container px-5"} breadcrumbs={false}>
      <Seo
        title={
          category && category.meta_title
            ? modifyCategoryMetaTitle(category.meta_title)
            : "Essential Product Categories on Sell Us Your Jewelry"
        }
        canonical={uniformUrl(pageContext.url || "/site-index")}
        description={
          category && category.meta_description
            ? modifyCategoryMetaDescription(category.meta_description)
            : "Discover how you can sell us your jewelry product categories for the best prices. Fast, secure, and easy process. Start earning from your jewelry now!"
        }
      />
      <h1 className=" text-link text-xl uppercase lg:ml-[332px] md:ml-[282px] ">Site Index</h1>
      {(pageContext?.categoryName || "").toString().toLowerCase() !== "site-index-main-page" && (
        <div
          className={
            "mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5"
          }
        >
          <div className={"flex flex-col"}>
            <div className="flex flex-row justify-between align-center">
              <div className="z-20 mb-8 text-5xl font-black text-gray sm:text-3xl md:text-4xl xl:text-5xl">
                {pageContext?.categoryName}
              </div>
              <Link className="py-2 text-left text-2xl" to="/site-index">
                <IconChevronLeft className={"text-accent"} /> Back to the site index page
              </Link>
            </div>

            <div
              className={
                "grid sm:grid-cols-2 lg:grid-cols-4 gap-6 pt-10 mt-7 border-t-2 border-accentMd"
              }
            >
              {ProductsList?.length > 0 &&
                ProductsList.map(product => {
                  return (
                    <Link
                      key={product.node.name}
                      className="py-2 text-left"
                      to={`/${product.node.url_key}`}
                    >
                      {product.node.name}
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>
      )}
      {firstLevel.length > 0 && (
        <div
          className={
            "mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5 "
          }
        >
          <div
            className={
              "grid sm:grid-cols-[250px_minmax(100px,_1fr)] lg:grid-cols-[300px_minmax(100px,_1fr)] gap-8 "
            }
          >
            <div className="sticky top-16 bg-white py-2">
              <div className="flex sm:flex-col sm:sticky top-20 md:top-4 items-center gap-3">
                <div className="z-20 mb-8 font-black text-gray-700 text-2xl uppercase w-3/4 sm:w-full order-2 sm:order-1">
                  Product Categories
                </div>
                <StaticImage
                  src={"../images/sell-my-watch-form-header.png"}
                  alt={"Watches and Jewelry Image"}
                  className={"mx-auto sm:w-full w-1/4 order-1 sm:order-2"}
                  objectFit="contain"
                />
              </div>
            </div>

            <div
              className={
                "gap-6 border-t-2 border-accentMd lg:columns-2 xl:columns-3 space-y-3 pt-3"
              }
            >
              {firstLevel.map(page => {
                const relatedSecondLevel = secondLevel.filter(
                  cat => cat.node.strapiParent.name === page.node.name
                )
                return (
                  <div key={page.node.name} className={"break-inside-avoid block"}>
                    {relatedSecondLevel.length > 0 ? (
                      <ListGroup
                        title={page.node.name}
                        content={relatedSecondLevel}
                        thirdLevel={thirdLevel}
                      />
                    ) : (
                      <Link className={"py-2 text-left "} to={`/site-index/${page.node.url}`}>
                        <h2 className="sm:text-xl text-accent font-bold underline decoration-1 underline-offset-4 decoration-accentMd uppercase">
                          {page.node.name}
                        </h2>
                      </Link>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {BlogPages.length > 0 && (
        <div
          className={
            "mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5"
          }
        >
          <div
            className={
              "grid sm:grid-cols-[250px_minmax(100px,_1fr)] lg:grid-cols-[300px_minmax(100px,_1fr)] gap-4"
            }
          >
            <div className="sticky top-16 bg-white py-2 ">
              <div className="flex sm:flex-col sm:sticky top-20 md:top-4 items-center gap-3">
                <div className="z-20 mb-8 font-black text-gray-700 text-2xl uppercase w-3/4 sm:w-full order-2 sm:order-1">
                  Blogs
                </div>
                <StaticImage
                  src={"../images/sell-us-your-jewelry-banner.png"}
                  alt={"Watches and Jewelry Image"}
                  className={"mx-auto sm:w-full w-1/4 order-1 sm:order-2"}
                  objectFit="contain"
                />
              </div>
            </div>

            <div className={"lg:columns-2 xl:columns-3 space-y-4 pt-10 border-t-2 border-accentMd"}>
              {BlogPages
                // sort by name
                .sort((a, b) => {
                  return a.node.title > b.node.title ? 1 : -1
                })
                .map(page => {
                  return (
                    <Link
                      key={page.node.name}
                      className="text-left block "
                      to={`/${page.node.slug}`}
                    >
                      {page.node.title}
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>
      )}
      {ReleasePages.length > 0 && (
        <div
          className={
            "mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5"
          }
        >
          <div
            className={
              "grid sm:grid-cols-[250px_minmax(100px,_1fr)] lg:grid-cols-[300px_minmax(100px,_1fr)] gap-8"
            }
          >
            <div className="sticky top-16 bg-white py-2">
              <div className="flex sm:flex-col sm:sticky top-20 md:top-4 items-center gap-3">
                <div className="z-20 mb-8 font-black text-gray-700 text-2xl uppercase w-3/4 sm:w-full order-2 sm:order-1">
                  Press Releases
                </div>
                <StaticImage
                  src={"../images/top-watch-buyer-img.png"}
                  alt={"Watches and Jewelry Image"}
                  className={"mx-auto sm:w-full w-1/4 order-1 sm:order-2"}
                  objectFit="contain"
                />
              </div>
            </div>

            <div
              className={
                "grid sm:grid-cols-1 lg:grid-cols-2 gap-4 h-auto grid-rows-[50px] pt-10  border-t-2 border-accentMd"
              }
            >
              {ReleasePages.map(page => {
                return (
                  <Link key={page.node.name} className="py-2 text-left" to={`/${page.node.slug}`}>
                    {page.node.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {OtherPages.length > 0 && (
        <div
          className={
            "mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5"
          }
        >
          <div
            className={
              "grid sm:grid-cols-[250px_minmax(100px,_1fr)] lg:grid-cols-[300px_minmax(100px,_1fr)] gap-8"
            }
          >
            <div className="sticky top-16 bg-white py-2">
              <div className="flex sm:flex-col sm:sticky top-20 md:top-4 items-center gap-3">
                <div className="z-20 mb-8 font-black text-gray-700 text-2xl uppercase w-3/4 sm:w-full order-2 sm:order-1">
                  Other Links
                </div>
                <StaticImage
                  src={"../images/image1.png"}
                  alt={"Watches and Jewelry Image"}
                  className={"mx-auto sm:w-full w-1/4 order-1 sm:order-2"}
                  objectFit="contain"
                />
              </div>
            </div>

            <div className={"lg:columns-2 xl:columns-3 space-y-4 pt-10 border-t-2 border-accentMd"}>
              {OtherPages.map(page => {
                return (
                  <Link
                    key={page.node.title}
                    className=" text-left block"
                    to={`/${page.node.slug}`}
                  >
                    {page.node.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
      <AdditionalFoms />
      {(pageContext?.categoryName || "").toString().toLowerCase() !== "site-index-main-page" &&
        ProductsList.length === 0 && (
          <>
            <HaveQuestions />
            <BrandsWeBuy />
          </>
        )}
    </Layout>
  )
}

export const query = graphql`
  query SiteIndexPageQuery($categoryName: [String!]) {
    category: strapiCategory(name: { in: $categoryName }) {
      meta_title
      meta_description
      url
    }
    products: allStrapiProduct(
      filter: { categories: { elemMatch: { name: { in: $categoryName } } } }
    ) {
      edges {
        node {
          url_key
          name
          categories {
            id
            name
          }
        }
      }
    }
  }
`

SiteIndex.propTypes = {
  pageContext: PropTypes.shape({
    url: PropTypes.string,
    categoryName: PropTypes.string,
    listPages: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
  data: PropTypes.shape({
    category: PropTypes.shape({
      meta_title: PropTypes.string,
      meta_description: PropTypes.string,
      url: PropTypes.string,
    }),
    products: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            url_key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        })
      ),
    }),
  }),
}

SiteIndex.defaultProps = {
  pageContext: null,
  data: null,
}

export default SiteIndex
